<template>
  <new-page>
    <div v-wechat-title="$route.meta.title"></div>
    <div class="about">
      <!-- 头像 -->
      <div class="profile">
        <div class="tag-group-left">
          <span
            class="tag-item"
            v-for="(item, index) in tagList_l"
            :key="index"
            >{{ item }}</span
          >
        </div>
        <div class="img">
          <img src="@/static/image/YDQ.png" object-fit="cover" style="width: 150px;height: 150px;border-radius: 50%;" />
        </div>
        <div class="tag-group-right">
          <span
            class="tag-item"
            v-for="(item, index) in tagList_r"
            :key="index"
            >{{ item }}</span
          >
        </div>
      </div>
      <div class="title">关于本站</div>
      <!-- 第一部分 -->
      <div class="author-content">
        <div class="author-content-item introduce">
          <div>Hello，大家好👋</div>
          <div
            style="
              font-size: 36px;
              font-weight: 700;
              line-height: 1.1;
              margin: 10px 0;
            "
          >
            我叫 叶德钦
          </div>
          <div>是一名前端开发工程师</div>
        </div>
        <div class="author-content-item aboutsiteTips">
          <div class="author-content-item-tips">追求</div>
          <h2>
            源于<br>
            热爱而去创造
            <div class="mask">
              <span class="first-tips" data-show>产品</span>
              <span>设计</span>
              <span>程序</span>
              <span>体验</span>
            </div>
          </h2>
        </div>
      </div>
      <!--  第二部分 -->
      <div class="author-content">
        <div class="author-content-item skills">

            <div class="author-content-item-tips">技能</div>
            <div class="author-content-item-title">开启创造力</div>
            <div class="skills-content">
              <div class="row-item">
                <div class="row-1">
                  <div v-for="(item, index) in row1" :key="index" class="img" :style="{backgroundColor: item.backgroundColor}">
                    <img :src="item.image" />
                  </div>
                </div>
                <div class="row-1">
                  <div v-for="(item, index) in row1" :key="index" class="img" :style="{backgroundColor: item.backgroundColor}">
                    <img :src="item.image" />
                  </div>
                </div>
              </div>
              <div class="row-item" style="margin-left: -70px;">
                <div class="row-2">
                  <div v-for="(item, index) in row2" :key="index" class="img" :style="{backgroundColor: item.backgroundColor}">
                    <img :src="item.image" />
                  </div>
                </div>
                <div class="row-2">
                  <div v-for="(item, index) in row2" :key="index" class="img" :style="{backgroundColor: item.backgroundColor}">
                    <img :src="item.image" />
                  </div>
                </div>
              </div>
            </div>

        </div>
        <div class="author-content-item">
          <div class="card-content">
            <div class="author-content-item-tips">生涯</div>
            <div class="author-content-item-title">无限进步</div>
            <div class="careers-group">
              <div class="careers-item">
                <div class="circle" style="background-color: #117dff;"></div>
                  计算机科学与技术
              </div>
              <div class="careers-item">
                <div class="circle" style="background-color: #3d58ff;"></div>
                  软件测试
              </div>
              <div class="careers-item">
                <div class="circle" style="background-color: #ff1111;"></div>
                  前端开发
              </div>
            </div>
          </div>
          <div style="position: absolute;bottom: 0px;width: 100%;left: 0px;">
              <img style="width: 100%;" src="@/static/image/career2.png" />
            </div>
        </div>
      </div>
      <!-- 第三部分 -->
      <div class="author-content">
        <div class="author-content-item personalities">
          <div class="author-content-item-tips">性格</div>
          <div class="author-content-item-title">物流师</div>
          <div class="title2" style="color: #4298b8;">ISTJ-T</div>
          <div class="post-tips">
            在
            <a href="https://www.16personalities.com/" target="_blank" ref="noopener nofollow">16personalities</a>
            了解更多关于
            <a href="https://www.16personalities.com/istj-personality" target="_blank" ref="noopener nofollow">物流师</a>
          </div>
          <div class="image">
            <img src="@/static/image/ISTJ_male.png" style="height: 400px;" />
          </div>
        </div>
        <div class="author-content-item doraemon" style="padding: 0;">
          <img src="@/static/image/doraemon.jpeg" object-fit="cover" style="width: 100%;height: 100%;" />
        </div>
      </div>
      <!-- 第四部分 -->
      <div class="author-content">
        <div class="author-content-item motto">
          <div class="author-content-item-tips">座右铭</div>
          <div class="author-content-item-title">生活百般滋味，</div>
          <div class="author-content-item-title">人生需要笑对。</div>
        </div>
        <div class="author-content-item strongPoint">
          <div class="author-content-item-tips">特长</div>
          <div class="author-content-item-title" style="color: #fff;opacity: .6;">善于解决程序中的BUG</div>
          <div class="author-content-item-title" style="color: #fff;">学习效率MAX</div>
        </div>
      </div>
      <!-- 第五部分 -->
      <!-- <div class="author-content">
        <div class="author-content-item" style="width: 100%;">
          <div class="author-content-item-tips">项目经验</div>
          <div class="timeline">
            <div class="circle" style="background-color: rgb(244, 237, 238);"></div>
            <div class="circle" style="background-color: rgb(246, 241, 224);"></div>
            <div class="circle" style="background-color: rgb(243, 245, 233);"></div>
            <div class="circle" style="background-color: rgb(230, 240, 237);"></div>
            <div class="circle" style="background-color: rgb(238, 241, 247);"></div>
            <div class="circle" style="background-color: rgb(242, 234, 245);"></div>
            <div class="circle" style="background-color: rgb(247, 237, 247);"></div>
          </div>
        </div>
      </div> -->
    </div>
  </new-page>
</template>
<script>
import NewPage from '../../src/components/NewPage'
import {
  onMounted,
  computed
} from 'vue'
export default {
  components: {
    NewPage
  },
  setup() {
    const tagList_l = [
      '🤖️乐高积木发烧友',
      '🔨热衷挑战未知性',
      '🔍 分享与热心帮助',
      '🏠 智能家居小能手'
    ]
    const tagList_r = [
      '做事沉着有逻辑🤝',
      '脚踏实地行动派 🏃',
      '团队小组发动机 🧱',
      '壮汉人狠话不多 💢'
    ]
    const skillsList = [
      {
        name: '',
        image: require('../static/image/3.png'),
        backgroundColor: '#66AEE7'
      },
      {
        name: '',
        image: require('../static/image/ai.png'),
        backgroundColor: '#f29e39'
      },
      {
        name: '',
        image: require('../static/image/ant.svg'),
        backgroundColor: '#E3F0FF'
      },
      {
        name: '',
        image: require('../static/image/css.png'),
        backgroundColor: '#2c51db'
      },
      {
        name: '',
        image: require('../static/image/element-plus.png'),
        backgroundColor: '#409eff'
      },
      {
        name: '',
        image: require('../static/image/git.png'),
        backgroundColor: '#df5b40'
      },
      {
        name: '',
        image: require('../static/image/hbuilderx.png'),
        backgroundColor: '#DBF9D8'
      },
      {
        name: '',
        image: require('../static/image/html.png'),
        backgroundColor: '#e9572b'
      },
      {
        name: '',
        image: require('../static/image/intellij-idea.svg'),
        backgroundColor: '#19191c'
      },
      {
        name: '',
        image: require('../static/image/js.png'),
        backgroundColor: '#f7cb4f'
      },
      {
        name: '',
        image: require('../static/image/Mysql.png'),
        backgroundColor: '#b9d3ee'
      },
      {
        name: '',
        image: require('../static/image/Nodejs.png'),
        backgroundColor: '#356B33'
      },
      {
        name: '',
        image: require('../static/image/postman.png'),
        backgroundColor: '#FFE7D5'
      },
      {
        name: '',
        image: require('../static/image/ts.png'),
        backgroundColor: '#294e80'
      },
      {
        name: '',
        image: require('../static/image/vite.svg'),
        backgroundColor: '#FFEFCA'
      },
      {
        name: '',
        image: require('../static/image/vscode.png'),
        backgroundColor: '#6895DB'
      },
      {
        name: '',
        image: require('../static/image/Vue.png'),
        backgroundColor: '#9CB0A4'
      },
      {
        name: '',
        image: require('../static/image/navcat.svg'),
        backgroundColor: '#DCF9D7'
      }
    ]
    onMounted(() => {
      pursuit()
    })
    const pursuit = () => {
      // 滚动
      var pursuitInterval = null
      pursuitInterval = setInterval(() => {
        const show = document.querySelector('span[data-show]')
        const next = show.nextElementSibling || document.querySelector('.first-tips')
        const up = document.querySelector('span[data-up]')

        if (up) {
          up.removeAttribute('data-up')
        }

        show.removeAttribute('data-show')
        show.setAttribute('data-up', '')

        next.setAttribute('data-show', '')
      }, 2000)
    }
    const row1 = computed(() => {
      const arr = []
      skillsList.forEach((skill, index) => {
        if (index % 2 === 0) {
          arr.push(skill)
        }
      })
      return arr
    })

    const row2 = computed(() => {
      const arr = []
      skillsList.forEach((skill, index) => {
        if (index % 2 !== 0) {
          arr.push(skill)
        }
      })
      return arr
    })

    return {
      tagList_r,
      tagList_l,
      skillsList,
      row1,
      row2
    }
  }
}
</script>
<style scoped>
.about {
  min-height: 800px;
  margin-top: 120px;
  color: #363636;
}

.profile {
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile .img {
  height: 100%;
  width: 180px;
  border-radius: 50%;
  /* border: 1px solid #000; */
  background-color: #fff;
  margin: 0 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile .img::before {
  content: '';
  transition: 1s;
  width: 30px;
  height: 30px;
  background: #57bd6a;
  position: absolute;
  border-radius: 50%;
  border: 5px solid #f7f9fe;
  bottom: 5px;
  right: 10px;
  z-index: 2;
}

.profile .tag-group-left {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.profile .tag-group-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.profile .tag-group-right .tag-item:first-child,
.profile .tag-group-right .tag-item:last-child {
  margin-left: -16px;
}

.profile .tag-group-left .tag-item:first-child,
.profile .tag-group-left .tag-item:last-child {
  margin-right: -16px;
}

.profile .tag-group-left .tag-item:nth-child(1) {
  animation-delay: 0s;
}

.profile .tag-group-left .tag-item:nth-child(2) {
  animation-delay: 0.6s;
}

.profile .tag-group-left .tag-item:nth-child(3) {
  animation-delay: 1.2s;
}

.profile .tag-group-left .tag-item:nth-child(4) {
  animation-delay: 1.8s;
}

.profile .tag-group-right .tag-item:nth-child(1) {
  animation-delay: 0s;
}

.profile .tag-group-right .tag-item:nth-child(2) {
  animation-delay: 0.6s;
}

.profile .tag-group-right .tag-item:nth-child(3) {
  animation-delay: 1.2s;
}

.profile .tag-group-right .tag-item:nth-child(4) {
  animation-delay: 1.8s;
}

.profile .tag-item {
  transform: translate(0, -4px);
  background-color: #fff;
  border-radius: 40px;
  padding: 1px 8px;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 700;
  color: rgb(54, 54, 54);
  border: 1px solid #e3e8f7;
  box-shadow: 0 5px 12px -5px rgba(102, 68, 68, 0);
  font-family: 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei';
  line-height: 2;
  letter-spacing: 1px;
  animation: 6s ease-in-out 0s infinite normal none running floating;
}

@keyframes floating {
  0% {
    transform: translate(0, -4px);
  }
  50% {
    transform: translate(0, 4px);
  }
  100% {
    transform: translate(0, -4px);
  }
}

.title {
  font-size: 2rem;
  font-weight: 700;
  margin: 1rem 0;
  line-height: 1;
  text-align: center;
  margin: 20px 0;
}

.author-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.author-content-item {
  width: 49%;
  border-radius: 12px;
  background: #fff;
  /* border: 1px solid #e3e8f7; */
  box-shadow: 0 8px 16px -4px rgba(44, 45, 48, 0.047);
  position: relative;
  overflow: hidden;
  padding: 20px 40px;
  animation: slide-in .6s 0s backwards;
}

@keyframes slide-in {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
}

.introduce {
  background: linear-gradient(120deg, #5b27ff 0, #00d4ff 100%);
  width: 59%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: 200%;
  animation: gradient 5s ease infinite;
}

.aboutsiteTips {
  width: 39%;
}

.author-content-item-tips {
  opacity: 0.8;
  font-size: 12px;
  margin-bottom: 10px;
}

.author-content-item-title {
  margin-bottom: 14px;
}

.author-content-item .author-content-item-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 1;
}

.aboutsiteTips h2 {
    margin-right: auto;
    font-size: 36px;
    font-family: Helvetica;
    line-height: 1.06;
    letter-spacing: -.02em;
    margin-top: 0;
}

.aboutsiteTips .mask span {
    display: block;
    box-sizing: border-box;
    position: absolute;
    top: 36px;
    padding-bottom: var(--offset);
    background-size: 100% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-repeat: no-repeat;
}

.aboutsiteTips .mask {
  height: 36px;
  overflow: hidden;
  position: relative;
  margin-top: 4px;
}
.aboutsiteTips .mask span:nth-child(1) {
    background-image: linear-gradient(45deg,#0ecffe 50%,#07a6f1);
}

.aboutsiteTips .mask span:nth-child(2) {
    background-image: linear-gradient(45deg,#18e198 50%,#0ec15d);
}

.aboutsiteTips .mask span:nth-child(3) {
    background-image: linear-gradient(45deg,#8a7cfb 50%,#633e9c);
}

.aboutsiteTips .mask span:nth-child(4) {
    background-image: linear-gradient(45deg,#fa7671 50%,#f45f7f);
}

.aboutsiteTips .mask span[data-up] {
    transform: translateY(-200%);
    transition: .5s transform ease-in-out;
}

.aboutsiteTips .mask span[data-show] {
    transform: translateY(-100%);
    transition: .5s transform ease-in-out;
}

.skills {
  height: 450px;
}

.author-content-item .card-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
}
.skills .skills-content {
  margin-top: 40px;
}

.skills .skills-content img{
  height: 72px;
  width: 72px;
}

.skills .skills-content .row-item{
  display: flex;
  margin: 15px;
}

.skills .skills-content .row-1 {
  display: flex;
  animation: rowleft 30s linear infinite;
}

.skills .skills-content .row-2 {
  display: flex;
  animation: rowleft 30s linear infinite;
}

@keyframes rowleft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
}
}

.skills .skills-content .row-1 .img,
.skills .skills-content .row-2 .img{
  height: 120px;
  width: 120px;
  flex-shrink: 0;
  margin: 0px 10px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 16px -3px rgba(0, 0, 0, .15);;
}

.careers-group {
  margin-top: 12px;
}

.careers-group .careers-item {
  display: flex;
  align-items: center;
  color: #3c3c43cc;
  margin-bottom: 5px;
}

.careers-group .careers-item .circle {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin-right: 10px;
}

.personalities {
  width: 59%;
}

.title2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.1;
}

.post-tips {
  color: #999999;
  font-size: 14px;
  margin-top: 50px;
}

.post-tips a {
  color: #999999;
  font-size: 14px;
}

.post-tips a:hover {
  color: #4298b8;
}

a {
  background-color: transparent;
  color: #363636;
  text-decoration: none;
  transition: all .3s ease-out 0s;
  overflow-wrap: break-word;
  -webkit-user-drag: none;
}

.personalities .image {
  position: absolute;
  right: 0px;
  top: 10px;
}

.doraemon {
  width: 39%;
}

.motto {
  width: 39%;
}

.strongPoint {
  width: 59%;
  background: linear-gradient(120deg, #ff27e8 0, #ff8000 100%);
  background-size: 200%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.timeline {
  display: flex;
}

.timeline .circle {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #00d4ff;
  padding: 4px;
  background-clip: content-box;
  border: 2px solid #0ec15d;
}

</style>
